import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import Login from './Login/Login.js';
import User from 'classes/users.js';
import SiteDashboard from './SiteDashboard.js';
import BaseURLPicker from './BaseURLPicker/BaseURLPicker.js';

import { library} from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js';
// import Alert from './Alert.js';

library.add(fal);
library.add(fas);
library.add(far);


class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoggedIn: false,
        baseUrlIsSet: false,
    };
    this.RenderLogin = this.RenderLogin.bind(this);
    this.RenderDashboard = this.RenderDashboard.bind(this);
    this.setLoginState = this.setLoginState.bind(this);
    this.setBaseUrlState = this.setBaseUrlState.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.RenderBaseURLPicker = this.RenderBaseURLPicker.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount(){
    this.setState({
        isLoggedIn: User.isLocalStorage(),
      });
  }
  setLoginState(isLoggedIn){
    this.setState({isLoggedIn: isLoggedIn});
  }
  setBaseUrlState(state){
    this.setState({baseUrlIsSet:state});
  }
  logout(){
    window.localStorage.removeItem('amatis_automated_test_baseURL');
    window.localStorage.removeItem('amatis_automated_test_token')
    this.setBaseUrlState(false);
    this.setLoginState(false);
  }
  RenderLogin(){
    return <Login
            setLoginState={this.setLoginState}
            isLoggedIn={this.state.isLoggedIn}
          />
  }
  RenderDashboard(){
    return (
            <SiteDashboard
              setLoginState={this.setLoginState}
              logout={this.logout}
            />
    );
  }
  RenderBaseURLPicker(){
    console.log(this)
    return (
            <BaseURLPicker
              setBaseUrlState={this.setBaseUrlState}
            />
    );
  }

  renderPage(){
    if(this.state.isLoggedIn===true){
      return <this.RenderDashboard />;
    //not logged in but past landing page
    }else if(this.state.baseUrlIsSet !== true){
        return <this.RenderBaseURLPicker/>;
    } else {
      return <this.RenderLogin/>;
    }
  }
  render() {
    return(
      <span className="page-container">
        <this.renderPage/>
      </span>
    )
  }
}

export default Root;

import React, { Component } from 'react';
import axios from 'axios';
import User from 'classes/users.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import AmatisHeader from 'components/app/Header.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Tooltip } from '@material-ui/core';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
});


class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowParallelTests: true,
            anchorElUpdate: null,
            anchorElTest: null,
            error: false,
            rowsPerPage: 10,
            page: 0,
            selectedBoard: '',
            testInfo: {
                "RACK BRAVO": {
                    name: 'Main Testbed at christian\'s House',
                    description: '3 RC, 2 DM, 1 SW, 2 PC, 1 PX, 3 DL',
                },
                ASE: {
                    name: 'LMBR-650 ASE Full Test',
                    description: '3 RC, 2 DM, 1 SW, 2 PC, 1 PX, 3 DL',
                },
                ASE_9d5_single: {
                    name: 'LMBR-600 One of Each',
                    description: '1 RC, 1 DM, 1 SW, 1 PC, 1 PX, 1 DL',
                },
                ART: {
                    name: 'One',
                    description: 'A description',
                },
                ASE_SWITCH: {
                    name: 'One Switch Quickie',
                    description: 'A single LMSW for running really quick tests',
                },
            },
            availableTests: [
                {
                    name: 'ptp_test',
                    display_name: 'PtP',
                    description: 'Ten Press, PtP, then Validate',
                },
                {
                    name: 'ten_press',
                    display_name: 'Ten Press',
                    description: 'Just wipe them out',
                }
            ]
        };
        this.renderTestBoardDescription = this.renderTestBoardDescription.bind(this);
        this.renderUpdateButton = this.renderUpdateButton.bind(this);
        this.renderTestButton = this.renderTestButton.bind(this);
        this.getAvailableTests = this.getAvailableTests.bind(this);
        this.handleTestSelection = this.handleTestSelection.bind(this);
        this.availableTestInfo = {
            'full_ptp_test': {
                display_name: 'Full PtP Test',
                description: 'Ten Press, PtP, then Validate',
            },
        };
    }
    componentDidMount(){
        // this.getTests();
    }
    handleDeployClick = (event, test) => {
        this.setState({ anchorElUpdate: event.currentTarget, selectedBoard: test });
    };
    handleTestClick = (event, test) => {
        console.log(test)
        let testBoards = this.props.testBoardsObj;
        let availableTests = [];
        //TODO: Better handling for names and descriptions
        // if(testBoards.hasOwnProperty(test)){
            console.log(this.props.testBoardsObj[test])
            for(let availableTest in testBoards[test].available_tests){
                let activeTest = testBoards[test].available_tests[availableTest];
                // let display_name = '';
                // if(this.state.testInfo.hasOwnProperty(test)){
                //     display_name = this.state.testInfo[test].name;
                // } else {
                //     display_name = availableTest;
                // }
                availableTests.push(
                    {
                        name: availableTest,
                        display_name: availableTest,
                        description: activeTest,

                    }
                )
            }
        // }
        this.setState({ anchorElTest: event.currentTarget, availableTests, selectedBoard: test});
        console.log(test, this.props.testBoardsObj[test]);
    };
    handleClose = () => {
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    };
    handleTestSelection= (type) => {
        const { selectedBoard } =  this.state;
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/run`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
        };
        // TODO: we need to be able to toggle the update types
        let payload = {
            update: false,
            test_board: selectedBoard,
            update_type: 'flash',
        }

        //For default, we just dont pass the test_board
        if(type !== 'default'){
            payload['test_type'] = type;
        }
        this.props.setActiveBoards([selectedBoard]);
        axios.post(url, payload, {headers: config.headers})
        .then(res => {
            console.log(res);
            if(res.status === 200){
                let updating = this.props.activeBoards.slice();
                updating.push(payload.test_board);
                this.props.setActiveBoards(updating);
                console.log(this.state.updating)
            }else{
                // console.log('set login state: ', this.props);
                // this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
        //OTA is default
        // this.updateTestBoard(board, '');
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    };
    handleOTAClick = (board) => {
        console.log(board);
        //OTA is default
        this.updateTestBoard(board, '');
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    };
    handleFlashClick = (board) => {
        console.log(board);
        this.updateTestBoard(board, 'flash');
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    };
    getTestDescription = (testID) => {
        if(!this.availableTestInfo.hasOwnProperty(testID)) return '';
        
        return `: ${this.availableTestInfo[testID].description}`;
    };
    updateTestBoard(board, type){
        //TODO: Use activeBoard
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/run`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            params: {
                update: true,
                update_type: 'flash',
                test_board: this.state.selectedBoard,
            }
        };

        if(type !== ''){
            config.params.update_type = type;
        }

        console.log(config, board, type)
        axios.get(url, config)
        .then(res => {
            console.log(res);
            if(res.status === 200){
                let updating = this.props.activeBoards.slice();
                updating.push(this.state.selectedBoard);
                this.props.setActiveBoards(updating);
                // console.log(this.state.updating)
            }else{
                console.log('set login state: ', this.props);
                this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
    };
    renderUpdateButton(props){
        let row = props.row;
        let updating = this.props.activeBoards;
        let rowInfo = {};
        if(this.props.testBoardsObj.hasOwnProperty(this.state.selectedBoard)){
            rowInfo = this.props.testBoardsObj[this.state.selectedBoard];
        } else {
            rowInfo = this.props.testBoardsObj[row];
        }

        if(updating.length > 0){
            if(!updating.includes(row)){
                return <span>-</span>;
            } else {
                return (
                    <FontAwesomeIcon
                        style={{color: '3f51b5', marginTop: 7, fontSize: 21}}
                        icon={['far', 'sync']} spin
                    />
                )
            }
        } else {
            if(!updating.includes(row)){
                return (
                    <span>
                    <Button
                        variant="outlined"
                        color="primary"
                        aria-owns={this.state.anchorElUpdate ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.handleDeployClick(event, row)}
                    >
                        Update
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorElUpdate}
                        open={Boolean(this.state.anchorElUpdate)}
                        onClose={this.handleClose}
                        >
                        <MenuItem
                            row ={row}
                            onClick={() => this.handleOTAClick(row)}
                        >
                            OTA
                        </MenuItem>
                        {
                            (rowInfo.flashomatic === true)
                            ?(
                                <MenuItem
                                    row ={row}
                                    onClick={() => this.handleFlashClick(row)}
                                >
                                    Flash
                                </MenuItem>
                            ):(null)
                        }

                    </Menu>
                    </span>
                );

            } else {
                return (
                    <FontAwesomeIcon
                        style={{color: '3f51b5', marginTop: 7, fontSize: 21}}
                        icon={['far', 'sync']} spin
                    />
                )
            }
        }
    }
    renderTestButton(props){
        let row = props.row;
        // console.log(row)
        let updating = this.props.activeBoards;
        let availableTests = this.state.availableTests;
        if(updating.length > 0 && !this.state.allowParallelTests){
            if(!updating.includes(row)){
                return <span>-</span>;
            } else {
                return (
                    <FontAwesomeIcon
                        style={{color: '3f51b5', marginTop: 7, fontSize: 21}}
                        icon={['far', 'sync']} spin
                    />
                )
            }
        } else {
            return (
                <span>
                <Button
                    variant="outlined"
                    color="primary"
                    aria-owns={this.state.anchorElTest ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.handleTestClick(event, row)}
                >
                    Run Test
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorElTest}
                    open={Boolean(this.state.anchorElTest)}
                    onClose={this.handleClose}
                    >
                    {availableTests.map((test, index) => {
                        //TODO: Figure out how to use description
                        return (
                            <MenuItem
                                row ={row}
                                key={index}
                                onClick={() => this.handleTestSelection(test['name'])}
                            >
                                <span>{test.display_name} {this.getTestDescription(test.display_name)}</span>
                            </MenuItem>
                        );
                    })}

                </Menu>
                </span>
            );
        }

    }
    getAvailableTests(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/options`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            params: {
                type: 'SOW3',
                // archive_name: versionName,
            }
        };
        axios.get(url, config)
        .then(res => {
            // console.log('resp', res.data);
            if(res.status === 200){
                this.setState({availableTests: res.data});
                // console.log(res.data.staged_firmware[0])
                console.log('done');
                this.getStagedFw();
            }else{
                // console.log('set login state: ', this.props);
                // this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    renderTestBoardDescription = (testBoardID) => {
        if ( this.state.testInfo.hasOwnProperty(testBoardID) ){
            return (<span>this.state.testInfo[testBoardID].description</span>);
        }

        return null;
    };
    render() {
        const { rowsPerPage, page, testInfo } = this.state;
        const { testBoards} = this.props;

        if(testBoards === null){
            return <h1>loading</h1>;
        }else{
            if(this.state.error === false){
                return (
                    <Paper  style={{overflowX: 'auto'}}>
                        <Table className="table" style={{overflowX: 'auto'}}>
                        <TableHead className="table-head">
                            <TableRow>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Description</TableCell>
                                {/* <TableCell align="center">Deploy FW</TableCell> */}
                                <TableCell align="center">Run Test</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {testBoards
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                let test = row
                                return (
                                    <TableRow key={index}>
                                        {/* <TableCell align="right">
                                            <FontAwesomeIcon className={iconString} icon={['fas', iconString]} />
                                        </TableCell> */}
                                        <TableCell align="left">{test}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {
                                                (testInfo.hasOwnProperty(row))
                                                ?  testInfo[row].name
                                                : '-'
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                (testInfo.hasOwnProperty(row))
                                                ?  testInfo[row].description
                                                : '-'
                                            }
                                        </TableCell>
                                        {/* <TableCell align="center">
                                            <this.renderUpdateButton
                                                row={row}
                                            />
                                        </TableCell> */}
                                        <TableCell align="center">
                                            <this.renderTestButton
                                                row={row}
                                            />
                                        </TableCell>
                                    </TableRow>
                            )})
                            }
                        </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={testBoards.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                );
            }else{
                return(
                    <h1>There was an error - your token is probably expired</h1>
                );
            }
        }
    }
}
export default withStyles(styles)(Tests);
import React, { Component } from 'react';
// import $ from 'jquery';
import User from 'classes/users.js';
import AmatisHeader from '../Header.js';
import axios from 'axios';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            invalidLogin: false,
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
        this.logout = this.logout.bind(this);
        this.handleCreateAccountClick = this.handleCreateAccountClick.bind(this);
    }

    handleEmailChange(event){
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event){
        this.setState({password: event.target.value});
    }

    handlePasswordKeyPress(event){
        var code = event.key;
        if(code === 'Enter') {
            this.login();
        }
    }
    login(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/login`;
        let data ={
            username: this.state.username,
            password: this.state.password
        };
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            'crossdomain': true,
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
        axios.post(url, data, {headers:headers})
        .then(res => {
            console.log('resp', res.data);
            if(res.status === 200){
                this.handleSuccesLogin(res.data);
            }else{
                this.props.setLoginState(false);
                User.logout();
            }
        }).catch(error => {
            console.log(error);
            this.handleErrorLogin()
        });
    }
    logout(){
        this.props.setLoginState(false);
        User.logout();
    }
    handleErrorLogin(){
        this.setState({invalidLogin: true});
        this.props.setLoginState(false);
    }
    handleSuccesLogin(data){
        let storage = window.localStorage;
        storage.setItem('amatis_automated_test_token', data.access_token);
        User.apiToken = data.access_token;
        this.setState({invalidLogin: false});
        this.props.setLoginState(true);
    }
    handleSubmit(event){
        this.login();
    }
    handleCreateAccountClick(e){
        e.preventDefault();
        this.props.setNewUserState(true);
    }
    render() {
        if(!this.props.isLoggedIn){
            return (
                <div id="login">
                    <div className="login-container">
                        <AmatisHeader tagLine="Login to Amatis Automated Test Portal"/>
                        {(this.state.invalidLogin) ? (
                            <div id="login-status-error">
                            <i className="icon-exclamation-sign"></i>
                            <p className="error">Invalid Username or Password</p>
                        </div>
                        ): (null)}

                        <div name="reg" id="login-form">
                            <input id="site-email" type="text" name="username" placeholder="Email Address" onChange={this.handleEmailChange}/>
                            <input id="site-pass" type="password" placeholder="Password" name="password"
                                onKeyDown={this.handlePasswordKeyPress}
                                onChange= {this.handlePasswordChange}/>
                            <div className="submit-button-container row">
                                <button id="site-submit" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else{
            return null;
        }
    }
}
  
export default Login;
  
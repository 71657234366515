import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './components/app/App';

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './scss/locations.scss';
import './scss/config-tables.scss';
import './scss/main.scss';
import './scss/menu.scss';
import './scss/page.scss';
import './scss/style.scss';
import './scss/variables.scss';
import './scss/mobile.scss';




ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

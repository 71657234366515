import React, { Component } from 'react';
import logo from 'Images/Amatis-White-Logo-V1-2.svg';
class AmatisHeader extends Component{
    render(){ 
        return (
            <div>
                <div className="desktop-header">
                    <div className="row">
                        <div className="logo-container col-xs-6">
                            <img alt='' className="img-responsive" src={logo}/>
                        </div>
                        <div className="col-xs-6">
                            <p id="site-header">{this.props.tagLine}</p>
                        </div>
                    </div>
                </div>
                <div className="mobile-header">
                    <div className="logo-container">
                        <img alt='' className="img-responsive" src={logo}/>
                    </div>
                    <p id="site-header">{this.props.tagLine}</p>
                </div>
            </div>
        );
    }
}

export default AmatisHeader;
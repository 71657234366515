import React, { Component } from 'react';
// import axios from 'axios';
// import User from 'classes/users.js';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TablePagination from '@material-ui/core/TablePagination';
// import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import AmatisHeader from 'components/app/Header.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Tooltip } from '@material-ui/core';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
});


class HighLevelCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spacing: 16,
            smGridSize: 12,
            mdGridSize: 6,
        };
        this.handleChange = key => (event, value) => {
            this.setState({
                [key]: value,
            });
        };

    }
    componentDidMount(){
        // this.getTests();
    }
    getBuildLink(version){
        return '';
        //TODO: Update for Amatis stuff
        // if (version !== null ){
        //     //TODO: Clean this up. But this is a hack to get the new structure working
        //     version = version[this.state.activeBoard].version;
        //     let buildNum = version.substr(-4);
        //     return (
        //         <a
        //             target='_blank'
        //             rel='noopener noreferrer'
        //             href={`https://bitbucket.org/wattstopper/ippan-root/addon/pipelines/home#!/results/${buildNum}`}
        //         >
        //             View Build
        //         </a>
        //     );
        // } else {
        //     return null;
        // }
    }
    humanReadableTime(time){
        let dt = new Date(time*1000);
        let dtStr = dt.toString();
        let indexOfGMT = dtStr.indexOf('GMT');
        let badDtStr = dtStr.slice(indexOfGMT, (indexOfGMT + 8));
        dtStr = dtStr
                .replace(badDtStr, '')
                .replace('Mountain Daylight Time', 'MDT')
                .replace('Mountain Standard Time', 'MST');

       return (dtStr);
    };
    renderStatusIcon(props){
        let test = props.test;
        let result = (typeof(test.result) === 'undefined') ? false : test.result;

        switch(test.status){
            case 'active':
                return (
                    <FontAwesomeIcon
                        style={{fontSize: 21, color:'3f51b5'}}
                        icon={['fas', 'sync']}
                        spin
                    />
                );
            case 'error':
                return (
                    <FontAwesomeIcon
                        style={{fontSize: 21, color:'f39c12'}}
                        icon={['fas', 'exclamation-triangle']}
                    />
                );
            case 'complete':
                if(result){
                    return <FontAwesomeIcon className={'check-circle'} icon={['fas', 'check-circle']} />
                } else {
                    return <FontAwesomeIcon className={'exclamation-circle'} icon={['fas', 'exclamation-circle']} />
                }
            default:
                return (
                    <FontAwesomeIcon
                        style={{fontSize: 21, color:'f39c12'}}
                        icon={['fas', 'warning']}
                    />
                );
        }
    }
    render() {
        const { spacing, smGridSize, mdGridSize } = this.state;
        const { activeBoards, lastTest, stagedFw, activeBoard } = this.props;
        let lastTestResult = {};
        console.log(lastTest)
        if (lastTest && Object.keys(lastTest)[0] && lastTest[Object.keys(lastTest)[0]]){
            lastTestResult = lastTest[Object.keys(lastTest)[0]];
        }
        let stagedVersionName = '';
        if(stagedFw && stagedFw[activeBoard]){
            stagedVersionName = stagedFw[activeBoard].version;
        }

        return (
            <span>
            <Grid container spacing={spacing}>
                <Grid item xs={smGridSize} sm={smGridSize} md={12}>
                    <Card >
                    <CardHeader
                        avatar={
                            <FontAwesomeIcon  style={{fontSize: 21, color:'3f51b5'}} icon={['fas', 'server']} />
                        }
                        // action={
                        //     <IconButton>
                        //     <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={`Active Test Boards: ${activeBoards.length}`}
                        subheader= {
                            (activeBoards.length > 0) ? (
                                activeBoards.map((board, index) =>{
                                        if(activeBoards.length -1 !== index){
                                            return `${board}, `;
                                        } else {
                                            return `${board}`;
                                        }
                                    })
                            ) : ('No active Tests')
                        }
                    />
                    </Card>
                </Grid>
                <Grid item xs={smGridSize} sm={smGridSize} md={mdGridSize}>
                    <Card >
                    <CardHeader
                        avatar={
                            <this.renderStatusIcon test={lastTestResult} />
                        }
                        // action={
                        //     <IconButton>
                        //     <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={`Last Test Status: ${lastTestResult.status}`}
                        subheader= {`Started: ${ this.humanReadableTime(lastTestResult.start_time)}`}
                    />
                    </Card>
                </Grid>
                {/* <Grid item xs={smGridSize} sm={smGridSize} md={mdGridSize}>
                    <Card >
                    <CardHeader
                        avatar={
                            <FontAwesomeIcon
                                style={{color: '3f51b5', fontSize: 21}}
                                icon={['far', 'tachometer-fast']}
                            />
                        }
                        // action={
                        //     <IconButton>
                        //     <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={`Total Tests Run: ${testResults.length}`}
                        subheader= {'Look at all those tests...'}
                    />
                    </Card>
                </Grid> */}
                <Grid item xs={smGridSize} sm={smGridSize} md={mdGridSize}>
                    <Card >
                    <CardHeader
                        avatar={
                            <FontAwesomeIcon
                                style={{color: '3f51b5', fontSize: 21}}
                                icon={['fas', 'download']}
                            />
                        }
                        // action={
                        //     <IconButton>
                        //     <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={`Staged for Update: ${stagedVersionName}`}
                        subheader= {`On Test Board: ${activeBoard}`}
                    />
                    </Card>
                </Grid>
                {/* <Grid item xs={smGridSize} sm={smGridSize} md={mdGridSize}>
                    <Card >
                    <CardHeader
                        avatar={
                            <FontAwesomeIcon
                                style={{color: '3f51b5', fontSize: 21}}
                                icon={['fas', 'download']}
                            />
                        }
                        // action={
                        //     <IconButton>
                        //     <MoreVertIcon />
                        //     </IconButton>
                        // }
                        title={`Staged for Flash: ${stagedFw[activeBoard].version}`}
                        subheader= {this.getBuildLink(stagedFw)}
                    />
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Grid container className={classes.demo} justify="center" spacing={Number(spacing)}>
                        <Grid key={value} item>
                            <Paper className={classes.paper} />
                        </Grid>
                        ))}
                    </Grid>
                </Grid> */}
            </Grid>
            </span>
        )
    }
}
export default withStyles(styles)(HighLevelCards);
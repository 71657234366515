import React, { Component } from 'react';
// import $ from 'jquery';
// import User from 'classes/users.js';
import AmatisHeader from '../Header.js';
import axios from 'axios';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js'

class BaseURLPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baseURL: window.localStorage.getItem('amatis_automated_test_baseURL') || '',
            password: '',
            invalidLogin: false,
        };
        this.handleBaseURLChange = this.handleBaseURLChange.bind(this);
        this.handleBaseURLKeyPress = this.handleBaseURLKeyPress.bind(this);
        this.handleConnect = this.handleConnect.bind(this);
        this.handleCreateAccountClick = this.handleCreateAccountClick.bind(this);
        this.testConnection = this.testConnection.bind(this);
    }
    componentDidMount(){
        this.getLastKnownGoodBaseURL();
    }
    handleBaseURLChange(event){
        this.setState({baseURL: event.target.value});
    }

    handleBaseURLKeyPress(event){
        var code = event.key;
        if(code === 'Enter') {
            this.testConnection();
        }
    }
    testConnection(){
        console.log('in here', this.state.baseURL);
        let url = `${this.state.baseURL}/ping`;
        let data ={};
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            'crossdomain': true,
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
        axios.get(url, data, {headers:headers})
        .then(res => {
            console.log('resp', res.data);
            this.handleSuccessConnectionTest(res.data);
            // if(res.status === 200){
            // }else{
            //     this.props.setLoginState(false);
            //     User.logout();
            // }
        }).catch(error => {
            // console.log('resp', res.data);
            // this.handleSuccessConnectionTest(res.data);
            console.log(error);
            this.handleErrorLogin()
        });
    }
    handleErrorLogin(){
        this.setState({invalidLogin: true});
        // this.props.setLoginState(false);
    }
    getLastKnownGoodBaseURL(data){
        const storage = window.localStorage;
        let lastKnownGoodBaseURL = storage.getItem('amatis_automated_test_baseURL');
        lastKnownGoodBaseURL = lastKnownGoodBaseURL || process.env.REACT_APP_BASE_URL;
        console.log('setting base to: ', lastKnownGoodBaseURL);
        this.setState({baseURL: lastKnownGoodBaseURL});
        // this.setState({lastKnownGoodBaseURL: lastKnownGoodBaseURL});
    }
    handleSuccessConnectionTest(data){
        let storage = window.localStorage;
        if(this.state.baseURL !== ''){
            storage.setItem('amatis_automated_test_baseURL', this.state.baseURL);
            // User.apiToken = data.access_token;
            this.setState({invalidLogin: false});
            this.props.setBaseUrlState(true);
            // this.props.setLoginState(true);
        } else {
            // this.setState({invalidLogin: false});
            this.props.setBaseUrlState(false);
        }
    }
    handleConnect(event){
        this.testConnection();
    }
    handleCreateAccountClick(e){
        e.preventDefault();
        this.props.setNewUserState(true);
    }
    render() {
        if(!this.props.isLoggedIn){
            return (
                <div id="login">
                    <div className="login-container">
                        <AmatisHeader tagLine="Start By Pointing at a Test Device"/>
                        {(this.state.invalidLogin) ? (
                            <div id="login-status-error">
                            <i className="icon-exclamation-sign"></i>
                            <p className="error">Unable to connect to server at this address</p>
                        </div>
                        ): (null)}

                        <div name="reg" id="login-form">
                            <input id="site-email" type="text" name="baseURL" placeholder="Test Device Address"
                                onChange={this.handleBaseURLChange}
                                onKeyDown={this.handleBaseURLKeyPress}
                                value={this.state.baseURL}
                            />
                            <div className="submit-button-container row">
                                <button id="site-submit" onClick={this.handleConnect}>
                                    Connect
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else{
            return null;
        }
    }
}
  
export default BaseURLPicker;
  
import React, { Component } from 'react';
import axios from 'axios';
import User from 'classes/users.js';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AmatisHeader from 'components/app/Header.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TestResults from './testResults/TestResults';
import Tests from './tests/Tests';
import FwVersions from './FwVersions/FwVersions';
import HighLevelCards from './HighLevelCards/HighLevelCards.js';
import Paper from '@material-ui/core/Paper';
import ReactJson from 'react-json-view';
import Button from '@material-ui/core/Button';
import ActionOverlay from 'components/app/status/Loading/action-overlay.js';

class SiteDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests : null,
            testBoards: null,
            testResults: null,
            error: false,
            availableFW: null,
            activeBoards: [],
            activeBoard: null,
            stagedFw: null,
            stagedFwFlash: null,
            testBoardsObj: {},
            detailedResults: {},
            anchorElUpdate: null,
            anchorElTest: null,
        };
        this.resultsPollInterval = null;
        this.boardsPollInterval = null;
        this.getStagedFwInterval = null;
        this.getTestResults = this.getTestResults.bind(this);
        this.getTestBoards = this.getTestBoards.bind(this);
        this.getAvailableFW = this.getAvailableFW.bind(this);
        this.processTestResults = this.processTestResults.bind(this);
        this.setActiveBoards = this.setActiveBoards.bind(this);
        this.getStagedFw = this.getStagedFw.bind(this);
        this.getStagedFwFlash = this.getStagedFwFlash.bind(this);
        this.setDetailedResults = this.setDetailedResults.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
    }
    componentDidMount(){
        this.getTestResults();
        this.getTestBoards();
        this.getAvailableFW();
        this.getStagedFw();
        // this.getStagedFwFlash();
        this.getAvailableFWInterval = setInterval(() => {this.getAvailableFW()}, 60000);
        this.getStagedFwInterval = setInterval(() => {this.getStagedFw()}, 30000);
        // this.getStagedFwFlashInterval = setInterval(() => {this.getStagedFwFlash()}, 5000);
        this.resultsPollInterval = setInterval(() => {this.getTestResults()}, 6000);
        this.boardsPollInterval = setInterval(() => {this.getTestBoards()}, 60000);
    }
    getTestResults(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/status?num_to_get=300`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            // console.log('resp', res.data);
            if(res.status === 200){
                this.processTestResults(res.data);
            }else{
                console.log('set login state: ', this.props);
                //this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            //this.props.setLoginState(false);
            // User.logout();
        });
    }
    setActiveBoards(activeBoards){
        this.setState({activeBoards});
    }
    processTestResults(results){
        let stateObj = {
            testResults: results,
            activeBoards: [],
        };

        //Look for any active tests
        for(let test of results){
            let key = Object.keys(test)[0];
            test = test[key];
            if(test.status === 'active'){
                stateObj.activeBoards.push(test.test_board);
            }
        }
        // console.log(results);
        this.setState(stateObj);

    }
    getStagedFw(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/firmware/stage_firmware`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            if(res.status === 200){
                let activeBoard = '';

                try{
                    activeBoard = Object.keys(res.data.staged_firmware)[0];
                } catch(e){}

                this.setState({stagedFw: res.data.staged_firmware, activeBoard});

            }else{
                console.log('set login state: ', this.props);
                //this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
    };
    getStagedFwFlash(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/firmware/flashomatic_staged`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            // console.log(res);
            if(res.status === 200){

                this.setState({stagedFwFlash: res.data.staged_firmware});
                // console.log(res.data.staged_firmware[0])
            }else{
                console.log('set login state: ', this.props);
                //this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
    };
    setDetailedResults(detailedResults){
        this.setState({detailedResults});
    }
    getTestBoards(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/config/test_boards`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            // console.log('TEST BOARDS', res.data);
            if(res.status === 200){
                this.setState(
                    {
                        testBoards: Object.keys(res.data),
                        testBoardsObj: res.data
                    }
                );
            }else{
                console.log('set login state: ', this.props);
                //this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            if (error.response) {
                const responseStatus = error.response.status;
                if(responseStatus === 422 || responseStatus === 401){
                    this.props.setLoginState(false);
                    User.logout();
                }
            }
        });
    }
    getAvailableFW(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/firmware/available`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            if(res.status === 200){
                this.setState({availableFW: res.data.available_firmware});
            }else{
                console.log('set login state: ', this.props);
                //this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        });
    }
    handleLogoutClick(){
        this.props.logout();
    }
    deleteTestQueue(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/queue`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.delete(url, config)
        .then(res => {
            // console.log('resp', res.data);
            console.log('Deleted the queue', res);

            // if(res.status === 200){
            //     this.processTestResults(res.data);
            // }else{
            //     console.log('Deleted the queue', this.props);
            //     //this.props.setLoginState(false);
            //     // User.logout();
            //     // this.setState({error: true});
            // }
        }).catch(error => {
            //this.props.setLoginState(false);
            // User.logout();
        });
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    }
    getTestQueue(){
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/test/queue`;
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            }
        };
        axios.get(url, config)
        .then(res => {
            document.getElementById('detailedResults').scrollIntoView()
            if(res.status === 200){
                this.setState({detailedResults:res.data})
            }
            // else{
            //     //this.props.setLoginState(false);
            //     // User.logout();
            //     // this.setState({error: true});
            // }
        }).catch(error => {
            //this.props.setLoginState(false);
            // User.logout();
        });
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    }
    handleMainMenuButtonClick = (event, ) => {
        this.setState({ anchorElTest: event.currentTarget});
    };
    handleMainMenuClose = () => {
        this.setState({ anchorElTest: null, anchorElUpdate: null });
    };
    renderMenu(){
        return (
            <>
            <Button
                    variant="outlined"
                    color="inherit"
                    aria-owns={this.state.anchorElTest ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.handleMainMenuButtonClick(event)}
                    style={{float: 'right', marginRight:'10px'}}
                >
                    <FontAwesomeIcon
                        style={{color: 'white', marginTop: 7, fontSize: 21}}
                        icon={['far', 'bars']}
                    />
                </Button>
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorElTest}
                open={Boolean(this.state.anchorElTest)}
                onClose={this.handleMainMenuClose}
                >
                <MenuItem
                    key={0}
                    onClick={() => this.handleLogoutClick()}
                >
                    <ListItemIcon >
                        <FontAwesomeIcon icon={['far', 'sign-out']} />
                    </ListItemIcon>
                    <ListItemText inset primary="Logout" />
                    {/* <span>Logout</span> */}
                </MenuItem>
                <MenuItem
                    key={1}
                    onClick={() => this.getTestQueue()}
                >
                    <ListItemIcon >
                        <FontAwesomeIcon icon={['far', 'list-ol']} />
                    </ListItemIcon>
                    <ListItemText inset primary="Get Queue" />
                </MenuItem>
                <MenuItem
                    key={2}
                    onClick={() => this.deleteTestQueue()}
                >
                    <ListItemIcon >
                        <FontAwesomeIcon icon={['far', 'trash']} />
                    </ListItemIcon>
                    <ListItemText inset primary="Clear Queue" />
                </MenuItem>

            </Menu>
        </>
        );
    }
    render() {
        const {
            testResults,
            testBoards,
            activeBoard,
            testBoardsObj,
            availableFW,
            activeBoards,
            stagedFw,
            stagedFwFlash,
            detailedResults,
        } = this.state;

        if(testResults === null){
            return <ActionOverlay />;
        }else{
            if(this.state.error === false){
                return (
                    <div id="site-dashboard">
                        <AmatisHeader tagLine="Amatis Automated Test Portal"/>
                        <this.renderMenu />
                        <div className="table-container">
                        <HighLevelCards
                            activeBoards = {activeBoards}
                            activeBoard = {activeBoard}
                            lastTest = {testResults[0]}
                            testResults = {testResults}
                            stagedFw = {stagedFw}
                            stagedFwFlash = {stagedFwFlash}
                        />
                            <h1 style ={{marginTop:'30px'}}>Test Results:</h1>
                            <TestResults
                                tests = {testResults}
                                setDetailedResults = {this.setDetailedResults}
                            />
                            <h1 id='detailedResults' style ={{marginTop:'30px'}}>
                                <span> Detailed Log: </span>
                                <Button
                                    style={{float: 'right'}}
                                    variant="contained"
                                    color="secondary"
                                    onClick={(e) => this.setState({detailedResults:{}})}
                                >
                                    <FontAwesomeIcon
                                        style={{fontSize: 21, marginRight:10}}
                                        icon={['far', 'trash-alt']}
                                    />
                                    Clear Detailed Log
                                </Button>
                            </h1>
                            <Paper style={{marginTop:30}}>
                                <ReactJson
                                    src={detailedResults}
                                    collapsed={3}
                                    theme={"tomorrow"}
                                    displayDataTypes={false}
                                />
                            </Paper>
                            <h1 style ={{marginTop:'30px'}}>Test Boards:</h1>
                            <Tests
                                testBoards = {testBoards}
                                testBoardsObj = {testBoardsObj}
                                testResults = {testResults}
                                activeBoards = {activeBoards}
                                setActiveBoards = {this.setActiveBoards}
                                getTestResults = {this.getTestResults}
                            />
                            <h1 style ={{marginTop:'30px'}}>Available FW:</h1>
                            <FwVersions
                                stagedFw = {stagedFw}
                                activeBoard = {activeBoard}
                                stagedFwFlash = {stagedFwFlash}
                                availableFW = {availableFW}
                            />
                    </div>
                  </div>
                );
            }else{
                return(
                    <h1>There was an error - your token is probably expired</h1>
                );
            }
        }
    }
}
export default SiteDashboard;

import React from 'react';

export default class LoadingA extends React.Component{
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="100" height="100" viewBox="0, 0, 240, 240">
                <defs>
                    <linearGradient id="Gradient_1" gradientUnits="userSpaceOnUse" x1="124.932" y1="20.025" x2="129.105" y2="219.975">
                        <stop offset="0%" stopColor="#FFFFFf" stopOpacity="0"/>
                        <stop offset="50%" stopColor="#FFFFFf" stopOpacity="1"/>
                        <stop offset="100%" stopColor="#FFFFFF" stopOpacity="1"/> 
                    </linearGradient>
                </defs>
                <path d="M80.317,178.661 C77.35,178.144 72.001,176.211 69.137,174.555 C62.926,171.001 58.785,166.066 56.922,160.062 C55.335,154.921 55.093,146.329 56.37,141.601 C57.647,136.874 59.165,134.458 60.683,132.215 C65.342,125.349 72.726,120 83.837,115.411 C97.053,109.924 109.337,107.785 138.288,105.852 C152.471,104.921 151.228,105.162 151.746,103.333 C151.988,102.436 153.437,97.329 151.677,92.36 C149.917,87.391 144.327,84.355 136.218,82.871 C128.109,81.387 117.784,81.997 111.718,82.871 C103.575,84.044 97.053,85.286 87.391,88.288 C83.975,89.358 81.215,90.324 81.077,90.221 C80.732,89.841 86.874,67.447 87.426,66.998 C88.219,66.411 90.236,65.883 96.984,64.72 C113.315,61.908 123.313,61.477 134.079,61.477 C144.845,61.477 154.534,63.236 158.302,64.134 C166.411,66.066 178.523,71.863 181.974,78.73 C184.907,84.458 185.252,95.362 182.94,107.267 C181.836,113.03 180.076,119.448 175.728,133.665 C169.62,153.609 166.032,167.619 165.342,174.313 L165.169,176.28 L148.399,176.28 C132.595,176.28 131.663,176.246 131.905,175.694 C132.388,174.451 134.872,164.375 134.769,163.961 C134.7,163.685 132.871,164.582 129.834,166.308 C124.658,169.275 113.34,176.211 99.296,178.696 C93.87,179.656 85.459,179.524 80.317,178.661 z M116.239,158.13 C124.969,156.715 133.147,152.471 137.667,147.053 C140.911,143.154 142.395,139.324 145.535,126.591 C146.329,123.451 147.019,120.621 147.122,120.311 C147.295,119.758 147.019,119.689 144.327,119.689 C138.426,119.689 119.965,122.174 113.444,123.83 C103.368,126.418 94.534,132.388 91.601,138.565 C90.738,140.462 90.497,141.429 90.393,143.879 C90.048,151.021 93.602,156.232 99.986,157.992 C103.471,158.992 110.752,159.027 116.239,158.13 z" fill="#FFFFFF" id="Shape" opacity="0.9" >
                </path>
                    <path d="M120,220 C64.772,220 20,175.228 20,120 C20,64.772 64.772,20 120,20 C175.228,20 220,64.772 220,120 C220,175.228 175.228,220 120,220 z M120.017,207.405 C168.28,207.405 207.405,168.28 207.405,120.017 C207.405,71.754 168.28,32.629 120.017,32.629 C71.754,32.629 32.629,71.754 32.629,120.017 C32.629,168.28 71.754,207.405 120.017,207.405 z" fill="url(#Gradient_1)" id="Combined-Shape">
                        <animateTransform attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 120 120"
                            to="360 120 120"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </path>
                {/* </path> */}
            </svg>
        );
    }
}

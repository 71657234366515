let User = {
    apiToken: '',
    User: {},
    //duplicate isLocalStorage function in app.js
    isLocalStorage: function () {
        var storage = window.localStorage;
        if (storage.length > 0) {
            try {
                //Check for token
                if (storage.getItem('amatis_automated_test_token') !== null) {
                    User.apiToken = storage.getItem('amatis_automated_test_token');
                } else {
                    return false;
                }
                return true;
            } catch (e) {
                console.log(e); //debug
                console.log('FAILED to get site info from local');
                return false;
            }
        } else {
            return false;
        }
    },
    isPermitted(rolesRequired = ['admin', 'company']){
        //takes in roles required do a thing
        //returns if the current user can do it
        rolesRequired = new Set(rolesRequired);
        if(rolesRequired.has(this.myUser.role)){
            return true;
        }else{
            return false;
        }
    },
    logout: function () {
        User.myUser = '';
        localStorage.removeItem('amatis_automated_test_token');
    },
};

export default User;
import React, { Component } from 'react';
import axios from 'axios';
import User from 'classes/users.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';
// import Tooltip from '@material-ui/core/Tooltip';

// import AmatisHeader from 'components/app/Header.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },

});


class FwVersions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            rowsPerPage: 5,
            page: 0,
            anchorEl: null,
            stagingFw: null,
            stagingFwStatus: false,
        };
        this.getStagedFwInterval = null;
        // this.renderTestBoardDescription = this.renderTestBoardDescription.bind(this);
        this.RenderFWActionButton = this.RenderFWActionButton.bind(this);
        this.handleStageClick = this.handleStageClick.bind(this);
    }
    componentDidMount(){
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    renderTestBoardDescription = (testBoardID) => {
        if ( this.state.testInfo.hasOwnProperty(testBoardID) ){
            return (<span>this.state.testInfo[testBoardID].description</span>);
        }

        return null;
    };

    handleStageClick(versionName, type=false, versionNameFull){
        const { activeBoard } = this.props;
        this.setState({stagingFw: versionName, stagingFwStatus:'staging'});
        let url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/firmware/stage_firmware`;

        // if(type && type === 'flash'){
        //     url = `${window.localStorage.getItem('amatis_automated_test_baseURL')}/api/firmware/stage_firmware`;
        // }

        // firmware/get_binaries?type={{binary_type}}&archive_name=ALPHA_BUILD_(10.02.24.3590)_20190416_121833.zip
        let config = {
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
        };

        let payload = {
            type: 'SOW3',
            archive_name: versionNameFull.replace('.zip', ''),
            test_board: activeBoard,
        };

        axios.put(url, payload, config)
        .then(res => {
            if(res.status === 200){
                //Add a delay greater than the poll period for staged FW
                console.log('Sucessfully staged')
            }else{

                // console.log('set login state: ', this.props);
                // this.props.setLoginState(false);
                // User.logout();
                // this.setState({error: true});
            }
        }).catch(error => {
            console.log(error)
            // this.props.setLoginState(false);
            // User.logout();
        }).finally(()=>{
            setTimeout(()=> this.setState({stagingFw: null, stagingFwStatus:false}), 5000);
        });
    };
    RenderFWActionButton(props){
        const {activeBoard, stagedFwFlash, stagedFw} = this.props
        const {flash, versionNameFull, version} = props
        const {stagingFw, stagingFwStatus } = this.state
        let stagedVersionName = '';
        if(stagedFw && stagedFw[activeBoard]){
            stagedVersionName = stagedFw[activeBoard].version;
        }
        if(!stagingFwStatus){
            if((flash !== 'flash' && versionNameFull.includes(stagedVersionName))
                || (flash === 'flash' && version.version.includes(stagedFwFlash))
            ){
                return(
                    <Button
                       variant="outlined"
                       color="primary"
                       onClick={(e) => alert('This Version is ready to be deployed. Deploy it to a test board next')}
                   >
                    <FontAwesomeIcon
                        style={{fontSize: 21, marginRight:10}}
                        icon={['far', 'check-circle']}
                    />
                    Staged
                   </Button>
                );
            } else {
                return(
                    <Button
                       variant="outlined"
                       color="primary"
                       onClick={() => this.handleStageClick(version.version, flash, versionNameFull)}
                   >
                        <FontAwesomeIcon
                            style={{fontSize: 21, marginRight:10}}
                            icon={['far', 'download']}
                        />
                       Stage Version
                   </Button>
               );
            }
        }else {
            if(stagingFw === version.version){
                return(
                    <FontAwesomeIcon
                        style={{color: '3f51b5', marginTop: 7, fontSize: 21}}
                        icon={['far', 'sync']} spin
                    />
                );
            } else {
                return '-';
            }
        }
    };
    humanReadableTime(time){
        let dt = new Date(time);
        let dtStr = dt.toString();
        let indexOfGMT = dtStr.indexOf('GMT');
        let badDtStr = dtStr.slice(indexOfGMT, (indexOfGMT + 8));
        dtStr = dtStr
                .replace(badDtStr, '')
                .replace('Mountain Daylight Time', 'MDT')
                .replace('Mountain Standard Time', 'MST');

       return (dtStr);
    };
    getBuildLink(row, versionName){
        if (row !== null && versionName !== null ){
            let versionNameArray = versionName.split('_');
            const versionNameArrayLength = versionNameArray.length;
            const versionNameProperIndex = versionNameArrayLength - 3;
            const shortVersionName = versionNameArray[versionNameProperIndex];
            const buildNum = shortVersionName.substr(-4);

            return (
                <>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://bitbucket.org/wattstopper/ippan-root/addon/pipelines/home#!/results/${buildNum}`}
                >
                    {shortVersionName}
                </a>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://bitbucket.org/wattstopper/ippan-root/downloads/${versionName}`}
                >
                    <FontAwesomeIcon
                        style={{
                            fontSize: 21,
                            marginLeft:15,
                            color:'3f51b5',
                            cursor: 'pointer',
                        }}
                        icon={['far', 'download']}
                    />
                </a>
                </>
            );
        } else {
            return null;
        }
    }
    render() {
        const { rowsPerPage, page } = this.state;
        let { availableFW } = this.props;

        if(availableFW === null || !Array.isArray(availableFW)){
            return <h1>loading</h1>;
        }else{
            if(this.state.error === false){
                // console.log(typeof availableFW, availableFW);
                // availableFW.values.map((version, i) => {
                //     console.log(version.name, version);
                //     return null;
                // })
                // return null;
                // ALPHA_BUILD_(10.02.28.3633)_20190418_224606.zip
                return (
                    <Paper  style={{overflowX: 'auto'}}>
                        <Table className="table" style={{overflowX: 'auto'}}>
                        <TableHead className="table-head">
                            <TableRow>
                                {/* <TableCell align="left">ID</TableCell> */}
                                {/* <TableCell align="left">User</TableCell> */}
                                <TableCell align="left">Build Name</TableCell>
                                <TableCell align="left">Build Date</TableCell>
                                <TableCell align="center">FW For Update</TableCell>
                                {/* <TableCell align="center">FW For Flash</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            availableFW.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {

                                const fwVersions = Object.keys(row);
                                let versionName  = '-';

                                if(fwVersions.length > 0){
                                    row = row[fwVersions[0]];
                                    versionName = fwVersions[0];
                                } else {
                                    return  null;
                                }
                                return (
                                    <TableRow key={index}>
                                        {/* <TableCell align="right">
                                            <FontAwesomeIcon className={iconString} icon={['fas', iconString]} />
                                        </TableCell> */}
                                        {/* <TableCell align="center">
                                            <Tooltip title={row.user.display_name}>
                                                <Avatar
                                                    style={{width:35, height:35}}
                                                    alt={row.user.display_name}
                                                    src={row.user.links.avatar.href}
                                                />
                                            </Tooltip> */}
                                            {/* <img src={row.user.links.avatar.href} alt={`./public/apple-touch-icon.png`}/> */}
                                            {/* {row.user.display_name} */}
                                        {/* </TableCell> */}
                                        <TableCell align="left">{this.getBuildLink(row, versionName)}</TableCell>
                                        <TableCell align="left">{this.humanReadableTime(row.created_on)}</TableCell>
                                        <TableCell align="center">
                                            <this.RenderFWActionButton row={row} flash={false} versionNameFull={versionName}/>
                                        </TableCell>
                                        {/* <TableCell align="center">
                                            <this.RenderFWActionButton row={row} flash={'flash'}/>
                                        </TableCell> */}
                                        {/* <TableCell component="th" scope="row">
                                            {
                                                (testInfo.hasOwnProperty(row))
                                                ?  testInfo[row].name
                                                : '-'
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                (testInfo.hasOwnProperty(row))
                                                ?  testInfo[row].description
                                                : '-'
                                            }
                                        </TableCell>
                                         */}
                                    </TableRow>
                            )})
                            }
                        </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={availableFW.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                );
            }else{
                return(
                    <h1>There was an error - your token is probably expired</h1>
                );
            }
        }
    }
}
export default withStyles(styles)(FwVersions);
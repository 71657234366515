import React, { Component } from 'react';
// import axios from 'axios';
// import User from 'classes/users.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
// import AmatisHeader from 'components/app/Header.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
// import ActionOverlay from 'components/app/status/Loading/action-overlay.js';
import { Doughnut } from 'react-chartjs-2';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';

class TestResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            rowsPerPage: this.getRows(),
            page: 0,
        };
        this.renderStatusIcon = this.renderStatusIcon.bind(this);
        this.iconClick = this.iconClick.bind(this);
    };
    getRows = () => {
        //Allow the number of results per page to be stored and retrieved locally
        let storedInfo = {};
        try {
            storedInfo = JSON.parse(window.localStorage.getItem('am-automation-results'));
        } catch(err) {};

        const returnVal = (storedInfo && storedInfo.rowsPerPage) ? storedInfo.rowsPerPage : 5;
        return returnVal;
    };
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        const rows = event.target.value
        this.setState({ rowsPerPage: rows });

        window.localStorage
            .setItem( 'am-automation-results',JSON.stringify({rowsPerPage: rows}));
    };
    humanReadableTime(time){
        let dt = new Date(time*1000);
        let dtStr = dt.toString();
        let indexOfGMT = dtStr.indexOf('GMT');
        let badDtStr = dtStr.slice(indexOfGMT, (indexOfGMT + 8));
        dtStr = dtStr
                .replace(badDtStr, '')
                .replace('Mountain Daylight Time', 'MDT')
                .replace('Mountain Standard Time', 'MST');

       return (dtStr);
    };
    renderStatusIcon(props){
        let test = props.test;
        let result = (typeof(test.result) === 'undefined') ? false : test.result;

        switch(test.status){
            case 'active':
                return (
                    <FontAwesomeIcon
                        style={{fontSize: 21, color:'3f51b5'}}
                        icon={['fas', 'sync']}
                        spin
                    />
                );
            case 'error':
                return (
                    <FontAwesomeIcon
                        style={{fontSize: 21, color:'f39c12'}}
                        icon={['fas', 'exclamation-triangle']}
                    />
                );
            case 'complete':
                if(result){
                    return (
                        <Tooltip title="View Logs Below" placement="top">
                            <FontAwesomeIcon
                                onClick={() => this.iconClick(test)}
                                className={'check-circle'}
                                icon={['fas', 'check-circle']}
                            />
                        </Tooltip>

                    );
                } else {
                    return (
                        <Tooltip title="View Logs Below" placement="top">
                            <FontAwesomeIcon
                                onClick={() => this.iconClick(test)}
                                className={'exclamation-circle'}
                                icon={['fas', 'exclamation-circle']}
                            />
                        </Tooltip>
                    )
                }
            default:
                return (
                    <FontAwesomeIcon
                        onClick={() => this.iconClick(test)}
                        style={{fontSize: 21, color:'f39c12'}}
                        icon={['fas', 'exclamation-triangle']}
                    />
                );
        }
    }
    renderTestID(props){
        let test = props.test;
        let id = props.testId;
        if(test.hasOwnProperty('s3_path')){
            return(
                <span>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={test.s3_path}
                >
                    {id}
                </a>
                <CopyToClipboard text={id}>
                    <Button>
                        <FontAwesomeIcon
                            icon={['far', 'copy']}
                            style={{'marginRight': '35px', 'marginBottom':'15px', 'color':'grey'}}
                        />
                    </Button>
                </CopyToClipboard>
                </span>
            )
        }else {
            return (
                <span>
                <span>{id}</span>
                <CopyToClipboard text={id}>
                    <Button>
                        <FontAwesomeIcon
                            icon={['far', 'copy']}
                            style={{'marginRight': '35px', 'marginBottom':'15px', 'color':'grey'}}
                        />
                    </Button>
                </CopyToClipboard>
                </span>
            );
        }
    }
    iconClick(test){
        this.props.setDetailedResults(test.detailed_results);
        // console.log(this.props.setDetailedResults())
    }
    render() {
        const { rowsPerPage, page } = this.state;

        if(this.props.tests === null){
            return <h1>loading</h1>;
        }else{
            if(this.state.error === false){
                return (
                    <Paper style={{overflowX: 'auto'}}>
                        <Table className="table" style={{overflowX: 'auto'}}>
                        <TableHead className="table-head">
                            <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Results</TableCell>
                            <TableCell align="center">Pass Rate</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Test Board</TableCell>
                            <TableCell align="center">Test Type</TableCell>
                            <TableCell align="center">FW Version</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell align="center">Logs</TableCell>
                            <TableCell>ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tests
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    //This 0 of keys thing cracks me up. Classic miscommunication :)
                                    //Imagine if we scoped stuff out first...
                                    let key = Object.keys(row)[0];
                                    let test = row[key]
                                    let humanFriendlyTime = '-';
                                    /* if(test.hasOwnProperty('results')){
                                        console.log(test.results);
                                    } */
                                    humanFriendlyTime = this.humanReadableTime(test.start_time);
                                    if(humanFriendlyTime == 'Invalid Date'){ // eslint-disable-line
                                        humanFriendlyTime = test.start_time;
                                    }
                                    let testResultsSummary = [];
                                    let testResultsLabels = [];
                                    let passedCount, failedCount, errorCount = 0;
                                    
                                    if(test.detailed_results){
                                        passedCount =  test.detailed_results.passed.length || 0;
                                        failedCount = test.detailed_results.failures.length || 0;
                                        errorCount = test.detailed_results.errors.length || 0;
                                        if(passedCount > 0) {
                                            testResultsSummary.push(passedCount);
                                            testResultsLabels.push('Passed');
                                        }
                                        if(failedCount > 0) {
                                            testResultsSummary.push(failedCount);
                                            testResultsLabels.push('Fails');
                                        }
                                        if(errorCount > 0) {
                                            testResultsSummary.push(errorCount);
                                            testResultsLabels.push('Errors');
                                        }
                                    }
                                    let successRate = Math.floor((passedCount/(passedCount+errorCount+failedCount))*100) || '-';
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="center" style={{fontSize:18}}>
                                                <this.renderStatusIcon test={test}/>
                                            </TableCell>
                                            <TableCell style={{width:100}}>
                                                <Doughnut
                                                    height={0}
                                                    style={{marginBottom:10, marginTop:-10}}
                                                    width={30}
                                                    options={
                                                        {
                                                            maintainAspectRatio: false,
                                                            legend: {
                                                                display: false,
                                                            },
                                                            labels: {
                                                                render: 'percentage',
                                                                fontColor: function (data) {
                                                                return 'black'
                                                                },
                                                                precision: 2
                                                            }
                                                        }
                                                    }
                                                    data={{
                                                        options:{
                                                            legend: {
                                                                display: false,
                                                            },
                                                        },
                                                        labels: testResultsLabels,
                                                        datasets: [
                                                            {
                                                                label: "Passed",
                                                                backgroundColor: ["green","red", "orange"],
                                                                borderColor:["green","red", "orange"],
                                                                data: testResultsSummary,
                                                            }
                                                        ]
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" width={100}>
                                                <span>
                                                    {/* <Tooltip title='View Logs Below' aria-label="ViewLogsBelow"> */}
                                                        {successRate}%
                                                    {/* </Tooltip> */}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left">{test.status}</TableCell>
                                            <TableCell align="left">{test.test_board}</TableCell>
                                            <TableCell align="center">{
                                                (test.hasOwnProperty('test_type'))
                                                ? (test.test_type) : ('-')
                                            }</TableCell>
                                            <TableCell align="center">{
                                                (test.hasOwnProperty('fw_version'))
                                                ? (test.fw_version) : ('-')
                                            }</TableCell>
                                            <TableCell component="th" scope="row">
                                                {`${humanFriendlyTime}`}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {
                                                    (test.hasOwnProperty('s3_path'))
                                                ?(
                                                    <span>
                                                    <Tooltip title='View Logs Below' placement="top">
                                                        <FontAwesomeIcon
                                                            onClick={() => this.iconClick(test)}
                                                            style={{
                                                                fontSize: 21,
                                                                marginRight:10,
                                                                color:'3f51b5',
                                                                cursor: 'pointer',
                                                            }}
                                                            icon={['far', 'eye']}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title='Download Full Logs' placement="top">
                                                        <a
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            href={test.s3_path}
                                                        >
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    fontSize: 21,
                                                                    marginRight:10,
                                                                    color:'3f51b5',
                                                                    cursor: 'pointer',
                                                                }}
                                                                icon={['far', 'download']}
                                                            />
                                                        </a>
                                                    </Tooltip>
                                                    </span>
                                                ) : (<span>-</span>)
                                                }

                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <this.renderTestID testId={key} test={test}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.props.tests.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                );
            }else{
                return(
                    <h1>There was an error - your token is probably expired</h1>
                );
            }
        }
    }
}
export default TestResults;